var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pa-10"},[_c('v-app-bar',{attrs:{"app":""}},[_c('v-toolbar-title',[_vm._v("الحسابات")]),_c('v-spacer')],1),_c('v-card',{staticClass:"pa-10",attrs:{"no-elevation":""}},[_c('v-tabs',{attrs:{"centered":""},model:{value:(_vm.selectedRoleId),callback:function ($$v) {_vm.selectedRoleId=$$v},expression:"selectedRoleId"}},[_c('v-tabs-slider',{attrs:{"color":_vm.$background}}),_vm._l((_vm.roles),function(role,index){return _c('v-tab',{key:role.idRole,staticStyle:{"justify-content":"center !important"}},[_vm._v(" "+_vm._s(role.roleName)+"      "),_c('v-chip',{attrs:{"dark":"","color":"red","x-small":""}},[_vm._v(" "+_vm._s(_vm.accounts.filter(function (user) { return user.roleId == _vm.roles[index].idRole; }) .length)+" ")])],1)})],2),_c('br'),_c('v-data-table',{attrs:{"height":'60vh',"fixed-header":"","items-per-page":-1,"items":_vm.accounts.filter(function (user) { return user.roleId == _vm.roles[_vm.selectedRoleId].idRole; }),"headers":_vm.userInfo.roleId != 1 ? _vm.tableHeaders : _vm.selectedRoleId == 3 ? _vm.tableHeadersForDelegates : _vm.selectedRoleId == 2 ? _vm.tableHeadersForSuperVisor : _vm.tableHeaders},scopedSlots:_vm._u([{key:"item.username",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{attrs:{"outlined":"","dense":"","hide-details":"","value":item.username},on:{"change":function($event){return _vm.changeUserName(item.idUser, $event)}}}),(item.idUserInfo == null && _vm.userInfo.roleId == 1)?_c('v-card',{staticClass:"pa-2 ma-2 white--text",attrs:{"color":"error"}},[_vm._v("يرجى تعيين سعر بيع للمستخدم")]):_vm._e()]}},(_vm.selectedRoleId == 3)?{key:"item.perms1",fn:function(ref){
var item = ref.item;
return [(_vm.checkPermission('canViewMonthlySales'))?_c('v-checkbox',{attrs:{"true-value":1,"false-value":0},on:{"change":function($event){return _vm.updatePermission(item.idUser, 'canViewMonthlySales', $event)}},model:{value:(item.canViewMonthlySales),callback:function ($$v) {_vm.$set(item, "canViewMonthlySales", $$v)},expression:"item.canViewMonthlySales"}}):_vm._e()]}}:null,(_vm.selectedRoleId == 3)?{key:"item.perms2",fn:function(ref){
var item = ref.item;
return [(_vm.checkPermission('canViewMonthlyRestores'))?_c('v-checkbox',{attrs:{"true-value":1,"false-value":0},on:{"change":function($event){return _vm.updatePermission(item.idUser, 'canViewMonthlyRestores', $event)}},model:{value:(item.canViewMonthlyRestores),callback:function ($$v) {_vm.$set(item, "canViewMonthlyRestores", $$v)},expression:"item.canViewMonthlyRestores"}}):_vm._e()]}}:null,(_vm.selectedRoleId == 3)?{key:"item.perms3",fn:function(ref){
var item = ref.item;
return [(_vm.checkPermission('canViewMonthlyDamaged'))?_c('v-checkbox',{attrs:{"true-value":1,"false-value":0},on:{"change":function($event){return _vm.updatePermission(item.idUser, 'canViewMonthlyDamaged', $event)}},model:{value:(item.canViewMonthlyDamaged),callback:function ($$v) {_vm.$set(item, "canViewMonthlyDamaged", $$v)},expression:"item.canViewMonthlyDamaged"}}):_vm._e()]}}:null,(_vm.selectedRoleId == 3)?{key:"item.perms4",fn:function(ref){
var item = ref.item;
return [(_vm.checkPermission('canViewDailyItems'))?_c('v-checkbox',{attrs:{"true-value":1,"false-value":0},on:{"change":function($event){return _vm.updatePermission(item.idUser, 'canViewDailyItems', $event)}},model:{value:(item.canViewDailyItems),callback:function ($$v) {_vm.$set(item, "canViewDailyItems", $$v)},expression:"item.canViewDailyItems"}}):_vm._e()]}}:null,(_vm.selectedRoleId == 3)?{key:"item.perms5",fn:function(ref){
var item = ref.item;
return [(_vm.checkPermission('canRestoreAllItems'))?_c('v-checkbox',{attrs:{"true-value":1,"false-value":0},on:{"change":function($event){return _vm.updatePermission(item.idUser, 'canRestoreAllItems', $event)}},model:{value:(item.canRestoreAllItems),callback:function ($$v) {_vm.$set(item, "canRestoreAllItems", $$v)},expression:"item.canRestoreAllItems"}}):_vm._e()]}}:null,(_vm.selectedRoleId == 3)?{key:"item.perms6",fn:function(ref){
var item = ref.item;
return [(_vm.checkPermission('canCreateCustomer'))?_c('v-checkbox',{attrs:{"true-value":1,"false-value":0},on:{"change":function($event){return _vm.updatePermission(item.idUser, 'canCreateCustomer', $event)}},model:{value:(item.canCreateCustomer),callback:function ($$v) {_vm.$set(item, "canCreateCustomer", $$v)},expression:"item.canCreateCustomer"}}):_vm._e()]}}:null,(_vm.selectedRoleId == 3)?{key:"item.perms7",fn:function(ref){
var item = ref.item;
return [(_vm.checkPermission('canViewContinueusCustomers'))?_c('v-checkbox',{attrs:{"true-value":1,"false-value":0},on:{"change":function($event){return _vm.updatePermission(item.idUser, 'canViewContinueusCustomers', $event)}},model:{value:(item.canViewContinueusCustomers),callback:function ($$v) {_vm.$set(item, "canViewContinueusCustomers", $$v)},expression:"item.canViewContinueusCustomers"}}):_vm._e()]}}:null,(_vm.selectedRoleId == 3)?{key:"item.perms8",fn:function(ref){
var item = ref.item;
return [(_vm.checkPermission('canViewDelegateRail'))?_c('v-checkbox',{attrs:{"true-value":1,"false-value":0},on:{"change":function($event){return _vm.updatePermission(item.idUser, 'canViewDelegateRail', $event)}},model:{value:(item.canViewDelegateRail),callback:function ($$v) {_vm.$set(item, "canViewDelegateRail", $$v)},expression:"item.canViewDelegateRail"}}):_vm._e()]}}:null,(_vm.selectedRoleId == 3)?{key:"item.perms9",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{attrs:{"true-value":1,"false-value":0},on:{"change":function($event){return _vm.updatePermission(item.idUser, 'canEditCustomer', $event)}},model:{value:(item.canEditCustomer),callback:function ($$v) {_vm.$set(item, "canEditCustomer", $$v)},expression:"item.canEditCustomer"}})]}}:null,(_vm.selectedRoleId == 2)?{key:"item.perms10",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{attrs:{"true-value":1,"false-value":0},on:{"change":function($event){return _vm.updatePermission(item.idUser, 'canViewDelegateRail', $event)}},model:{value:(item.canViewDelegateRail),callback:function ($$v) {_vm.$set(item, "canViewDelegateRail", $$v)},expression:"item.canViewDelegateRail"}})]}}:null,(_vm.selectedRoleId == 2)?{key:"item.perms11",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{attrs:{"true-value":1,"false-value":0},on:{"change":function($event){return _vm.updatePermission(item.idUser, 'canViewMonthlySales', $event)}},model:{value:(item.canViewMonthlySales),callback:function ($$v) {_vm.$set(item, "canViewMonthlySales", $$v)},expression:"item.canViewMonthlySales"}})]}}:null,(_vm.selectedRoleId == 2)?{key:"item.perms12",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{attrs:{"true-value":1,"false-value":0},on:{"change":function($event){return _vm.updatePermission(item.idUser, 'canViewContinueusCustomers', $event)}},model:{value:(item.canViewContinueusCustomers),callback:function ($$v) {_vm.$set(item, "canViewContinueusCustomers", $$v)},expression:"item.canViewContinueusCustomers"}})]}}:null,(_vm.selectedRoleId == 2)?{key:"item.perms13",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{attrs:{"true-value":1,"false-value":0},on:{"change":function($event){return _vm.updatePermission(item.idUser, 'canCreateCustomer', $event)}},model:{value:(item.canCreateCustomer),callback:function ($$v) {_vm.$set(item, "canCreateCustomer", $$v)},expression:"item.canCreateCustomer"}})]}}:null,(_vm.selectedRoleId == 2)?{key:"item.perms14",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{attrs:{"true-value":1,"false-value":0},on:{"change":function($event){return _vm.updatePermission(item.idUser, 'canEditCustomer', $event)}},model:{value:(item.canEditCustomer),callback:function ($$v) {_vm.$set(item, "canEditCustomer", $$v)},expression:"item.canEditCustomer"}})]}}:null,{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.changePassword(item.idUser)}}},[_c('v-icon',{attrs:{"title":item.idInvoice}},[_vm._v("mdi-key")])],1),(_vm.checkPermission('account_edit'))?_c('v-btn',{attrs:{"icon":"","to":'/user/' + item.idUser}},[_c('v-icon',{attrs:{"title":item.idInvoice}},[_vm._v("mdi-account-edit-outline")])],1):_vm._e(),(_vm.checkPermission('account_edit'))?_c('v-btn',{attrs:{"plain":"","small":""},on:{"click":function($event){return _vm.unblock(item.idUser)}}},[_vm._v(" فتح الحظر ")]):_vm._e()]}}],null,true)})],1),_c('v-dialog',{attrs:{"width":"500"},model:{value:(_vm.passwordDialog),callback:function ($$v) {_vm.passwordDialog=$$v},expression:"passwordDialog"}},[_c('v-card',[_c('v-card-title',[_vm._v(" تغيير كلمة المرور ")]),_c('v-card-text',[_c('v-text-field',{attrs:{"outlined":"","hide-details":"","type":"password","label":"كلمة المرور الجديدة"},model:{value:(_vm.newPasswordField),callback:function ($$v) {_vm.newPasswordField=$$v},expression:"newPasswordField"}})],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.changePasswordConfirm()}}},[_vm._v(" تغيير ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }